import axios from 'axios';

export const fetcher = (url) => axios.get(url).then((res) => res.data);

// Check if element is visible inside the viewport
export const isInViewport = (element) => {
  if (!element) return false;

  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getStxAddress = (stxAddress = '', number) => {
  const firstFive = stxAddress.substring(0, number || 4);
  const lastFive = stxAddress.substr(stxAddress.length - (number || 4));
  const username = `${firstFive}...${lastFive}`;
  return username;
};

export const truncate = (input, max) => (input.length > max ? `${input.substring(0, max)}...` : input);

export const validURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};
