import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import moment from 'moment';
import { Box, Flex, Text, Avatar, Icon, Image, Spinner, Tooltip, Button, useColorMode } from '@chakra-ui/react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { BsPlayCircleFill, BsPauseCircleFill } from 'react-icons/bs';
import DropdownMenu from './DropdownMenu';
import { price } from 'utils/utility';
import { getAllTokenData } from 'utils/stacks';
import NotForSale from './NotForSale';
import CurrentBid from './CurrentBid';
import Sold from './Sold';
import ReactHowler from 'react-howler';
import BuyNowUsd from './BuyNowUsd';
import BuyNowStx from './BuyNowStx';
import AvailableEditions from './AvailableEditions';
import { truncate } from 'lib/utility';
import { bgColor } from 'config/data';
import { CollectionIcon } from 'theme/icons';

const CardCollection = ({
  id,
  nftData = {},
  onOpenModal,
  onDeleteFavorite,
  isUser,
  favorites = [],
  onReport,
  onHide,
  isLoading,
  filter = false,
  isCollection = false,
  type,
  setId,
}) => {
  const {
    contract_token_id,
    name,
    user,
    id: nftId,
    // non_fungible_token_collection_id,
    non_fungible_token_collection,
    price_usd,
    price_micro_stx,
    for_sale,
    auction,
    is_sold,
    file,
    pack,
    edition,
    edition_index,
    non_fungible_token_collection_index,
  } = nftData;

  const non_fungible_token_collection_id = non_fungible_token_collection?.id;
  // console.log('nftData', nftData);

  // console.log('edition_index', edition_index);
  // console.log('non_fungible_token_collection_index', non_fungible_token_collection_index);
  // console.log('pack', pack);
  const img = nftData?.most_recent_non_fungible_token?.file?.thumbnail_http_url || '/images/no_image.jpg';
  const usr = nftData?.minter || {};

  const image = type === 'collections' ? img : file?.thumbnail_http_url;
  const usuario = type === 'collections' ? usr : user;

  const [tokenData, setTokenData] = useState(null);
  const [forSale, setForSale] = useState(false);
  const [end, setEnd] = useState(false);

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (auction) {
      setEnd(moment().isAfter(auction.end_at));
    }
    if (for_sale === null) {
      if (contract_token_id) {
        getAllTokenData(contract_token_id)
          .then((tokenData) => {
            // setTokenData((tokenData.metaData['price'].value / 1000000).toFixed(2));
            setTokenData(tokenData?.metaData['price']?.value);
            setForSale(tokenData?.metaData?.['for-sale']?.value || false);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [auction, contract_token_id, for_sale]);

  const exist = favorites.find((el) => el.id === nftId);
  const urlNft = isCollection
    ? `/nft/${nftId}`
    : contract_token_id
    ? `/nft/${nftId}`
    : pack
    ? `/nft/pack/${nftId}`
    : `/nft/${nftId}`; // End

  return (
    <Box borderRadius="8px" borderWidth={colorMode === 'dark' ? 0 : 1} bg={bgColor[colorMode]}>
      <Flex flexDirection="column">
        <Box pos="relative">
          <Box id="image" pos="relative">
            {pack ? (
              pack?.is_sealed ? (
                <Flex id="image" justifyContent="center" bg="#006BA2" borderRadius="8px 8px 0 0">
                  <Link href={`/nft/pack/${nftId}`}>
                    <a>
                      {/* <Flex flexDirection="column" p="30px" alignItems="center">
                        
                        <Image
                          src={pack?.thumbnail_http_url || '/images/no_image.jpg'}
                          alt=""
                          objectFit="contain"
                          w={{ base: '100%', md: '301px' }}
                          height={filter ? '220px' : '301px'}
                        />
                      </Flex> */}
                      <Image
                        src="/images/packs/cover_top.png"
                        w="145px"
                        alt="top"
                        pos="absolute"
                        left="24.5%"
                        top="19px"
                      />
                      <Image
                        p="30px"
                        src={pack?.thumbnail_http_url || '/images/no_image.jpg'}
                        alt=""
                        objectFit="contain"
                        w={{ base: '100%', md: '301px' }}
                        height={filter ? '220px' : '301px'}
                      />
                    </a>
                  </Link>
                </Flex>
              ) : (
                <Link href={`/nft/pack/${nftId}`}>
                  <a>
                    <Image
                      src={file?.thumbnail_http_url}
                      // src="/images/pack.jpg"
                      alt=""
                      borderRadius="8px 8px 0 0"
                      objectFit="cover"
                      w={{ base: '100%', md: '301px' }}
                      height={filter ? '220px' : '301px'}
                    />
                  </a>
                </Link>
              )
            ) : file?.file_type === 3 ? (
              <Flex
                bgGradient="linear-gradient(to bottom, #3a00ff, #007eff)"
                w="100%"
                height={filter ? '220px' : '301px'}
                borderRadius="8px 8px 0 0"
                objectFit="cover"
                justifyContent="center"
                alignItems="center"
                pos="relative"
              >
                <Link href={type === 'collections' ? `/collection/${nftData?.id}` : urlNft}>
                  <a>
                    <Image src="/images/icon-music-folder.svg" alt="" />
                  </a>
                </Link>
                <ReactHowler
                  html5={true}
                  src={file?.file_http_url}
                  playing={id === nftData.id ? true : false}
                  onEnd={() => setId(null)}
                />
                <Icon
                  as={id === nftData.id ? BsPauseCircleFill : BsPlayCircleFill}
                  pos="absolute"
                  bottom={4}
                  left={4}
                  onClick={() => (nftData?.id === id ? setId(null) : setId(nftData?.id))}
                  zIndex={2}
                  cursor="pointer"
                  color="#fff"
                  fontSize="47px"
                />
              </Flex>
            ) : (
              <Link href={type === 'collections' ? `/collection/${nftData?.id}` : urlNft}>
                <a>
                  <Image
                    src={image || '/images/no_image.jpg'}
                    alt=""
                    borderRadius="8px 8px 0 0"
                    objectFit="cover"
                    w={{ base: '100%', md: '301px' }}
                    height={filter ? '220px' : '301px'}
                  />
                </a>
              </Link>
            )}
          </Box>
          {non_fungible_token_collection_id && (
            <Link href={`/collection/${non_fungible_token_collection_id}`}>
              <a>
                <Button
                  pos="absolute"
                  bottom="10px"
                  right="10px"
                  bg={bgColor[colorMode]}
                  fontSize="14px"
                  zIndex={2}
                  borderRadius={5}
                >
                  <CollectionIcon color={colorMode === 'dark' ? '#fff' : '#141416'} />

                  {non_fungible_token_collection?.name && (
                    <Box ml="9px">{truncate(non_fungible_token_collection?.name, 24)}</Box>
                  )}
                </Button>
              </a>
            </Link>
          )}
        </Box>
        <Flex p="12px" justifyContent="space-between" id="card_title">
          <Flex flexDirection="column" id="info" w="100%">
            <Link href={type === 'collections' ? `/collection/${nftData?.id}` : urlNft}>
              <a>
                <Text fontSize="16px" fontWeight="semibold">
                  {name}
                </Text>
              </a>
            </Link>
          </Flex>

          <Flex alignItems="center">
            {/* {isCollection && (
              <Tooltip
                opacity="0.6"
                label="Lorem ipsum dolor sit amet."
                placement="top-end"
                fontWeight="bold"
                bg="white"
                color="#000"
                borderRadius="5px"
                py="3px"
              >
                <Box>
                  <Icon fontSize="23px" cursor="pointer" color="#00C783" as={RiLeafFill} mr="10px" />
                </Box>
              </Tooltip>
            )} */}
            {type !== 'collections' && (
              <Icon
                fontSize="23px"
                cursor="pointer"
                color={exist ? 'red.500' : ''}
                as={exist ? AiFillHeart : AiOutlineHeart}
                onClick={() => (exist ? onDeleteFavorite(nftId) : onOpenModal(nftData, 'favorite'))}
              />
            )}
          </Flex>
        </Flex>

        {!isEmpty(nftData?.edition) && (
          <Flex id="editions" p="0 10px" mt="-10px" color="#0179FF">
            {isCollection ? (
              <Text fontWeight="bold" fontSize="12px" onClick={() => onOpenModal(nftData, 'editions')} cursor="pointer">
                Edition {edition_index && edition_index} of {edition.non_fungible_tokens_count}
              </Text>
            ) : (
              <Text fontWeight="bold" fontSize="12px" onClick={() => onOpenModal(nftData, 'editions')} cursor="pointer">
                {nftData?.edition?.non_fungible_tokens_count} Editions
              </Text>
            )}
          </Flex>
        )}

        <Flex justifyContent="space-between" p="0 12px 12px" alignItems="center" id="card_bottom">
          <Link href={`/${usuario?.username}`}>
            <a>
              <Flex alignItems="center" cursor="pointer">
                <Flex pos="relative">
                  <Avatar name={usuario?.username} w="28px" h="28px" mr="10px" size="sm" bg="#BC3153" color="#fff" />
                  {usuario?.is_verified && (
                    <Image
                      pos="absolute"
                      bottom="1px"
                      right="7px"
                      w="14px"
                      h="14px"
                      src="/images/check_profile.svg"
                      alt=""
                    />
                  )}
                </Flex>

                <Text fontSize="14px">{usuario?.username}</Text>
                {usuario?.country?.iso_alpha_2 && (
                  <Image
                    src={`https://flagcdn.com/16x12/${usuario?.country?.iso_alpha_2.toLocaleLowerCase()}.png`}
                    alt=""
                    ml="10px"
                    alignSelf="center"
                  />
                )}
              </Flex>
            </a>
          </Link>
          {isUser ? (
            isLoading && Number(id) === Number(contract_token_id) ? (
              <Spinner />
            ) : (
              <DropdownMenu nftData={nftData} onReport={onReport} onHide={onHide} />
            )
          ) : auction ? (
            end ? (
              <NotForSale nftData={nftData} onOpenModal={onOpenModal} />
            ) : (
              <CurrentBid auction={auction} nftData={nftData} price_usd={price_usd} onOpenModal={onOpenModal} />
            )
          ) : for_sale ? (
            <BuyNowUsd
              price_usd={price_usd}
              price_micro_stx={price_micro_stx}
              nftData={nftData}
              onOpenModal={onOpenModal}
            />
          ) : for_sale === null ? (
            forSale ? (
              <BuyNowStx nftData={nftData} onOpenModal={onOpenModal} tokenData={tokenData} />
            ) : (
              <NotForSale id="not_for_sale_contract" nftData={nftData} onOpenModal={onOpenModal} />
            )
          ) : is_sold && !forSale ? (
            nftData?.edition ? (
              <AvailableEditions nftData={nftData} onOpenModal={() => onOpenModal(nftData, 'editions')} />
            ) : (
              <Sold />
            )
          ) : type === 'collections' ? (
            <Link href={type === 'collections' ? `/collection/${nftData?.id}` : urlNft}>
              <a>
                <Flex
                  bg="#373737"
                  flexDirection="column"
                  borderRadius="3px"
                  px="13px"
                  py="2px"
                  color="white"
                  cursor="pointer"
                >
                  <Text fontSize="10px" color="#d0d0d0">
                    FLOOR
                  </Text>
                  <Text fontWeight="500" lineHeight="1.2em">
                    {nftData?.statistics?.minimum_price ? price(nftData?.statistics?.minimum_price / 100) : '-'}
                  </Text>
                </Flex>
              </a>
            </Link>
          ) : (
            <NotForSale nftData={nftData} onOpenModal={onOpenModal} />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
export default CardCollection;
