import React, { Fragment } from 'react';
import { Button, Flex, InputGroup, Text, Textarea, useClipboard, useColorMode } from '@chakra-ui/react';
import { borderColor } from 'config/data';

const CopyEmbed = ({ embed }) => {
  const { onCopy, hasCopied } = useClipboard(embed);
  const { colorMode } = useColorMode();
  return (
    <Fragment>
      <Text fontSize="14px" fontWeight="500" mb="10px">
        Embed code
      </Text>
      <Flex
        borderWidth={1}
        borderColor={borderColor[colorMode]}
        p="10px 12px"
        borderRadius={8}
        fontSize="14px"
        cursor="pointer"
        onClick={onCopy}
      >
        <Text mb="20px">{embed}</Text>
      </Flex>

      <Button variant="black" mt="20px" onClick={onCopy} w="100%">
        {hasCopied ? 'Copied' : 'Copy'}
      </Button>
    </Fragment>
  );
};

export default CopyEmbed;
