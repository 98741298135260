import React from 'react';
import { Flex, Text, Image } from '@chakra-ui/react';
import { peso } from 'utils/utility';

const CurrentBid = ({ auction, nftData, onOpenModal, price_usd }) => {
  return (
    <Flex
      bg="#373737"
      flexDirection="column"
      borderRadius="3px"
      px="13px"
      py="2px"
      color="white"
      cursor="pointer"
      onClick={() => onOpenModal(nftData, 'bid')}
    >
      <Text fontSize="10px" color="#d0d0d0">
        CURRENT BID
      </Text>
      <Flex>
        <Image src="/images/bid_icon.png" alt="" />
        <Text fontWeight="500" lineHeight="1.2em" textAlign="center">
          {auction?.highest_bid === null ? peso(price_usd) : peso(auction?.highest_bid?.amount / 100)}
        </Text>
      </Flex>
    </Flex>
  );
};
export default CurrentBid;
