import React, { useState, useEffect } from 'react';
import { Flex, Text, Spinner } from '@chakra-ui/react';
import { API } from 'config/api';
import Sold from './Sold';

const AvailableEditions = ({ nftData, onOpenModal }) => {
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(0);

  useEffect(() => {
    setLoading(true);
    let isSubscribed = true;
    API.get(`/nft/edition/${nftData?.edition?.id}`)
      .then((res) => {
        if (isSubscribed) {
          const availables = res.data.data.filter((el) => (!el.is_sold && el.for_sale) || el.auction !== null);
          setLoading(false);
          setAvailable(availables.length || 0);
        }
      })
      .catch(() => {
        setLoading(false);
      });
    return () => (isSubscribed = false);
  }, []);

  if (loading)
    return (
      <Flex bg="#f7f7f7" borderRadius="3px" px="10px" py="11px" w="90px" justifyContent="center">
        <Spinner size="xs" color="gray" />
      </Flex>
    );

  return (
    <Flex id="status" textAlign="center">
      {available === 0 ? (
        <Sold />
      ) : (
        <Flex
          bg="#373737"
          flexDirection="column"
          borderRadius="3px"
          px="13px"
          py="2px"
          color="white"
          cursor="pointer"
          onClick={() => onOpenModal(nftData, 'purchase')}
        >
          <Text fontSize="10px" color="#d0d0d0">
            AVAILABLE
          </Text>
          <Text fontWeight="500" lineHeight="1.2em" fontSize="12px">
            {available} of {nftData?.edition?.non_fungible_tokens_count}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default AvailableEditions;
