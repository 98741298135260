import React, { Fragment } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { price, priceStx } from 'utils/utility';
import { useRouter } from 'next/router';
// import useUser from 'lib/useUser';
import { useCurrency } from 'contexts/currency/currency.provider';

const BuyNowUsd = ({ nftData, onOpenModal, price_usd, price_micro_stx }) => {
  const { currency } = useCurrency();
  const router = useRouter();
  // const { user } = useUser();
  // const user_id = nftData?.user?.id;
  // user_id !== user.id
  return (
    <Fragment>
      <Flex
        bg="#373737"
        flexDirection="column"
        borderRadius="3px"
        px="13px"
        py="2px"
        color="white"
        cursor="pointer"
        onClick={() => (nftData?.pack ? router.push(`/nft/pack/${nftData?.id}`) : onOpenModal(nftData, 'purchase'))}
      >
        <Text fontSize="10px" color="#d0d0d0">
          BUY NOW
        </Text>
        <Text fontWeight="500" lineHeight="1.2em" textAlign="center">
          {currency === 'usd' ? price(price_usd || 0) : priceStx(price_micro_stx)}
        </Text>
      </Flex>
    </Fragment>
  );
};
export default BuyNowUsd;
