import React, { Fragment } from 'react';
import { Flex, Image, Button, Text } from '@chakra-ui/react';

const SuccessBid = ({ onButton }) => {
  const message = "You're the top bidder.";
  return (
    <Fragment>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" pt="70px">
        <Flex bg="#fafafa" p="8px 7px 7px 8px" w="120px" h="120px" borderRadius="50%" justifyContent="center">
          <Image src="/images/icon-bid.svg" w="57px" h="83px" alt="" />
        </Flex>
        <Text fontSize="16px" fontWeight="bold" mt="20px">
          Your bid is placed successfully.
        </Text>
        <Text mt="10px" mb="40px" fontSize="14px" px="100px" textAlign="center" fontWeight="500">
          {message}
        </Text>
      </Flex>
      <Flex py="15px" px="35px" borderTopWidth={2} justifyContent="center">
        <Button variant="black" w="100%" onClick={onButton}>
          Close
        </Button>
      </Flex>
    </Fragment>
  );
};
export default SuccessBid;
