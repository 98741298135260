import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Flex, Image, Text, Spinner, useColorMode } from '@chakra-ui/react';
import Link from 'next/link';
import DropdownMenu from 'components/CardCollection';
import CurrentBid from 'components/CardCollection/CurrentBid';
import Sold from 'components/CardCollection/Sold';
import NotForSale from 'components/CardCollection/NotForSale';
import BuyNowUsd from 'components/CardCollection/BuyNowUsd';
import BuyNowStx from 'components/CardCollection/BuyNowStx';
import { getAllTokenData } from 'utils/stacks';
import { borderColor } from 'config/data';
import { useRouter } from 'next/router';

const ItemEdition = ({ type, nftData, isUser = false, onOpenModal, setVisible }) => {
  const {
    contract_token_id,
    name,
    id: nftId,
    price_usd,
    price_micro_stx,
    for_sale,
    auction,
    is_sold,
    edition_index,
    file,
  } = nftData;

  const [tokenData, setTokenData] = useState(null);
  const [forSale, setForSale] = useState(false);
  const [end, setEnd] = useState(false);
  const { colorMode } = useColorMode();
  const router = useRouter();
  useEffect(() => {
    if (auction) {
      // console.log(auction.end_at);
      setEnd(moment().isAfter(auction.end_at));
    }
    if (for_sale === null) {
      if (contract_token_id) {
        getAllTokenData(contract_token_id)
          .then((tokenData) => {
            // setTokenData((tokenData.metaData['price'].value / 1000000).toFixed(2));
            setTokenData(tokenData?.metaData['price']?.value);
            setForSale(tokenData?.metaData?.['for-sale']?.value || false);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [auction, contract_token_id, for_sale]);

  const onRedirectNft = () => {
    router.push(`/nft/${nftId}`);
    setVisible(false);
  };

  return (
    <Flex justifyContent="space-between" p="15px 20px" borderBottomWidth={2} borderColor={borderColor[colorMode]}>
      <Flex>
        <Image
          w="80px"
          h="80px"
          src={file?.thumbnail_http_url || '/images/no_image.jpg'}
          alt=""
          mr="15px"
          borderRadius={10}
        />
        <Flex flexDirection="column">
          <Text fontWeight="bold" onClick={() => onRedirectNft()} cursor="pointer">
            {name}
          </Text>
          <Text fontSize="13px">Edition {edition_index && edition_index} </Text>
        </Flex>
      </Flex>
      <Flex alignSelf="center">
        {isUser ? (
          isLoading && Number(id) === Number(contract_token_id) ? (
            <Spinner />
          ) : (
            <DropdownMenu nftData={nftData} onReport={onReport} onHide={onHide} />
          )
        ) : auction ? (
          end ? (
            <NotForSale nftData={nftData} onOpenModal={onOpenModal} />
          ) : (
            <CurrentBid auction={auction} nftData={nftData} price_usd={price_usd} onOpenModal={onOpenModal} />
          )
        ) : is_sold ? (
          <Sold />
        ) : for_sale ? (
          <BuyNowUsd
            price_usd={price_usd}
            price_micro_stx={price_micro_stx}
            nftData={nftData}
            onOpenModal={onOpenModal}
          />
        ) : for_sale === null ? (
          forSale ? (
            <BuyNowStx nftData={nftData} onOpenModal={onOpenModal} tokenData={tokenData} />
          ) : (
            <NotForSale id="not_for_sale_contract" nftData={nftData} onOpenModal={onOpenModal} />
          )
        ) : type === 'collections' ? (
          <Link href={type === 'collections' ? `/collection/${nftData?.id}` : urlNft}>
            <a>
              <Flex
                bg="#373737"
                flexDirection="column"
                borderRadius="3px"
                px="13px"
                py="2px"
                color="white"
                cursor="pointer"
              >
                <Text fontSize="10px" color="#d0d0d0">
                  FLOOR
                </Text>
                <Text fontWeight="500" lineHeight="1.2em">
                  {nftData?.statistics?.minimum_price ? price(nftData?.statistics?.minimum_price / 100) : '-'}
                </Text>
              </Flex>
            </a>
          </Link>
        ) : (
          <NotForSale />
        )}
      </Flex>
    </Flex>
  );
};

export default ItemEdition;
