import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { deviceType } from 'react-device-detect';
import { useColorMode } from '@chakra-ui/react';
import { PaginationWrapper } from './component.style';

const Pagination = ({ pagination, onPageChange, size, ...props }) => {
  const [device, setDevice] = useState('');

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setDevice(deviceType);
    }
    return () => (isSubscribed = false);
  }, []);

  const onChange = (data) => {
    onPageChange(data.selected + 1);
  };

  const { colorMode } = useColorMode();
  const background = colorMode === 'dark' ? '#141414' : '#fff';

  return (
    <PaginationWrapper
      size={size}
      device={device}
      bg={background}
      borderColor={colorMode === 'dark' ? 'rgba(255, 255, 255, 0.16)' : '#E2E8F0'}
    >
      <ReactPaginate
        previousLabel="previous"
        nextLabel="next"
        breakLabel="..."
        breakClassName="break-me"
        pageCount={pagination?.last_page || 1}
        forcePage={pagination?.current_page - 1}
        onPageChange={onChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        renderOnZeroPageCount={null}
        {...props}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
