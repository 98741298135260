import styled from '@emotion/styled';
// const color = "#3182ce";
const color = '#000';
export const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  /* justify-content: center; */
  padding: 20px 10px 30px;
  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 5px 0;
    border-radius: 4px;
  }
  .pagination > li {
    display: inline;
  }
  .pagination > li > a,
  .pagination > li > span {
    border-radius: 7px;
    position: relative;
    float: left;
    font-size: ${(props) => (props.device === 'mobile' ? '13px' : props.size === 'sm' ? '12px' : '15px')};
    padding: ${(props) => (props.device === 'mobile' ? '2px 8px' : '6px 12px')};
    line-height: 1.42857;
    text-decoration: none;
    color: ${color};
    background-color: ${(props) => props.bg || '#fff'};
    border-color: ${(props) => props.borderColor || '#ddd'};
    border-width: 1px;
    margin-left: -1px;
    margin-right: ${(props) => (props.device === 'mobile' ? '7px' : '10px')};
    margin-bottom: 15px;
  }
  .pagination > li:first-of-type > a,
  .pagination > li:first-of-type > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  .pagination > li > a:focus,
  .pagination > li > a:hover,
  .pagination > li > span:focus,
  .pagination > li > span:hover {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
  }
  .pagination > .active > a,
  .pagination > .active > a:focus,
  .pagination > .active > a:hover,
  .pagination > .active > span,
  .pagination > .active > span:focus,
  .pagination > .active > span:hover {
    z-index: 3;
    color: #fff;
    background-color: ${color};
    border-color: ${color};
    cursor: default;
  }
  .pagination > .disabled > a,
  .pagination > .disabled > a:focus,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > span,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > span:hover {
    color: #777;
    background-color: ${(props) => props.bg || '#fff'};
    border-color: ${(props) => props.borderColor || '#ddd'};
    cursor: not-allowed;
  }
  .pagination-lg > li > a,
  .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 19px;
    line-height: 1.33333;
  }
  .pagination-lg > li:first-of-type > a,
  .pagination-lg > li:first-of-type > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .pagination-lg > li:last-child > a,
  .pagination-lg > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .pagination-sm > li > a,
  .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 13px;
    line-height: 1.5;
  }
  .pagination-sm > li:first-of-type > a,
  .pagination-sm > li:first-of-type > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .pagination-sm > li:last-child > a,
  .pagination-sm > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  .pager {
    padding-left: 0;
    margin: 21px 0;
    list-style: none;
    text-align: center;
  }
  .pager:after,
  .pager:before {
    content: ' ';
    display: table;
  }
  .pager:after {
    clear: both;
  }
  .pager li {
    display: inline;
  }
  .pager li > a,
  .pager li > span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
  }
  .pager li > a:focus,
  .pager li > a:hover {
    text-decoration: none;
    background-color: #eee;
  }
  .pager .next > a,
  .pager .next > span {
    float: right;
  }
  .pager .previous > a,
  .pager .previous > span {
    float: left;
  }
  .pager .disabled > a,
  .pager .disabled > a:focus,
  .pager .disabled > a:hover,
  .pager .disabled > span {
    color: #777;
    background-color: #fff;
    cursor: not-allowed;
  }
`;
