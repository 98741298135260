import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { priceStx } from 'utils/utility';

const BuyNowStx = ({ nftData, onOpenModal, tokenData }) => {
  return (
    <Flex
      bg="#373737"
      flexDirection="column"
      borderRadius="3px"
      px="13px"
      py="2px"
      color="white"
      cursor="pointer"
      onClick={() => onOpenModal(nftData, 'purchase')}
    >
      <Text fontSize="10px" color="#d0d0d0">
        BUY NOW
      </Text>
      <Text fontWeight="500" lineHeight="1.2em" fontSize="12px">
        {priceStx(tokenData)}
      </Text>
    </Flex>
  );
};
export default BuyNowStx;
