import React from 'react';
import { Flex, Text, SimpleGrid, useColorMode } from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { Input, Select } from 'components/InputForm';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import CopyEmbed from './copy';
import { bgColor } from 'config/data';

const ModalEmbed = ({ nftData, isOpen, onClose }) => {
  const { colorMode } = useColorMode();
  const urlEmbed =
    process.env.NEXT_PUBLIC_ENV === 'staging'
      ? `https://staging.heylayer.com/embed/nft/${nftData?.id}`
      : process.env.NEXT_PUBLIC_ENV === 'dev'
      ? `http://localhost/embed/nft/${nftData?.id}`
      : `https://marketplace.heylayer.com/embed/nft/${nftData?.id}`;

  const url =
    process.env.NEXT_PUBLIC_ENV === 'staging'
      ? `https://staging.heylayer.com/nft/${nftData?.id}`
      : `https://marketplace.heylayer.com/nft/${nftData?.id}`;
  return (
    <Formik initialValues={{ width: '380', height: '500', theme: 'light' }} onSubmit={(values) => console.log(values)}>
      {({ handleSubmit, submitCount, values }) => {
        const embed = `<iframe frameBorder="0" width="${values?.width || '380'}" height="${
          values?.height || '500'
        }" src="${urlEmbed + `?theme=${values.theme}`}"></iframe>`;
        return (
          <Modal size="4xl" isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent minH="300px" bg={bgColor[colorMode]} color={colorMode === 'dark' ? 'gray.300' : null}>
              <ModalCloseButton />
              <ModalBody pt="20px" pb="20px">
                <Flex>
                  <Flex id="info" flex="1" flexDirection="column" mr="35px">
                    <Text fontSize="19px" fontWeight="bold" mb="5px">
                      Embed this NFT
                    </Text>
                    <Text fontSize="14px" mb="20px">
                      Our embed is responsive.
                    </Text>

                    <form onSubmit={handleSubmit}>
                      <SimpleGrid columns={[1, 2]} spacing="20px">
                        <Field
                          component={Input}
                          type="number"
                          name="width"
                          label="Width"
                          submitCount={submitCount}
                          height="42px"
                          fontWeight="500"
                        />
                        <Field
                          component={Input}
                          type="number"
                          name="height"
                          label="Height"
                          submitCount={submitCount}
                          height="42px"
                          fontWeight="500"
                        />
                      </SimpleGrid>
                      <Field
                        component={Select}
                        isClearable={false}
                        name="theme"
                        options={[
                          {
                            label: 'Light',
                            value: 'light',
                          },
                          {
                            label: 'Dark',
                            value: 'dark',
                          },
                        ]}
                        label="Theme"
                        placeholder="Select"
                        submitCount={submitCount}
                        height="42px"
                        fontWeight="500"
                      />

                      <CopyEmbed embed={embed} />
                    </form>
                  </Flex>

                  <Flex id="preview" w="380px" flexDirection="column">
                    <Text fontSize="18px" fontWeight="bold" mb="5px" ml="15px">
                      Preview
                    </Text>
                    <iframe
                      frameBorder="0"
                      width={values.width || '380'}
                      height={values.height || '500'}
                      src={urlEmbed + `?theme=${values.theme}`}
                    ></iframe>
                  </Flex>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ModalEmbed;
