import React from 'react';
import { Divider, useColorMode } from '@chakra-ui/react';

import { borderColor } from 'config/data';

const DividerTitle = () => {
  const { colorMode } = useColorMode();
  return <Divider borderWidth={1} borderColor={borderColor[colorMode]} mb="5px" />;
};

export default DividerTitle;
