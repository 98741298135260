import React, { Fragment } from 'react';
import { IconButton, Menu, MenuButton, MenuList, MenuItem, useDisclosure, useColorMode } from '@chakra-ui/react';
import { FiAlertCircle, FiMoreHorizontal } from 'react-icons/fi';
import { HiDownload } from 'react-icons/hi';
import { FaFacebook, FaTwitter } from 'react-icons/fa';
import { ImEmbed } from 'react-icons/im';
import { shareOnFacebook, shareOnTwitter } from 'utils/utility';
import ModalEmbed from 'components/Modal/Embed';
import { bgColor } from 'config/data';

const DropdownMenu = ({ nftData, onReport, isDetail = false, mynfts = [] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const exist = mynfts?.find((el) => el.id === nftData.id);
  const onOpenLink = (url) => {
    if (typeof window !== 'undefined') {
      window.open(url);
    }
  };

  const { colorMode } = useColorMode();

  const url =
    process.env.NEXT_PUBLIC_ENV === 'staging'
      ? `https://staging.heylayer.com/nft/${nftData?.id}`
      : `https://heylayer.com/nft/${nftData?.id}`;

  return (
    <Fragment>
      <ModalEmbed isOpen={isOpen} onClose={onClose} nftData={nftData} />
      <Menu>
        {isDetail ? (
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FiMoreHorizontal fontSize="18px" />}
            variant="outline"
            bg={colorMode === 'dark' ? '#141414' : '#eee'}
            border={0}
            size="xs"
          />
        ) : (
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FiMoreHorizontal fontSize="21px" />}
            variant="outline"
            border={0}
          />
        )}

        <MenuList
          fontSize="14px"
          minWidth="170px"
          p={0}
          bg={bgColor[colorMode]}
          borderColor={colorMode === 'dark' ? '#333' : null}
        >
          {/* <MenuItem
            icon={<FaFacebook color="#0079ff" fontSize="18px" />}
            borderBottomWidth={1}
            py="8px"
            fontWeight="500"
            onClick={() => shareOnFacebook(url)}
          >
            Share on Facebook
          </MenuItem> */}

          <MenuItem
            icon={<FaTwitter color="#55acee" fontSize="18px" />}
            borderBottomWidth={1}
            borderColor={colorMode === 'dark' ? '#333' : null}
            py="8px"
            fontWeight="500"
            onClick={() => shareOnTwitter(url)}
          >
            Share on Twitter
          </MenuItem>

          <MenuItem
            icon={<ImEmbed fontSize="18px" />}
            borderBottomWidth={1}
            borderColor={colorMode === 'dark' ? '#333' : null}
            py="8px"
            fontWeight="500"
            onClick={onOpen}
          >
            Embed
          </MenuItem>

          <MenuItem
            icon={<FiAlertCircle fontSize="18px" />}
            borderBottomWidth={1}
            borderColor={colorMode === 'dark' ? '#333' : null}
            py="8px"
            fontWeight="500"
            onClick={() => onReport({ id: nftData.id, name: nftData?.name })}
          >
            Report
          </MenuItem>

          {exist && (
            <MenuItem
              icon={<HiDownload fontSize="18px" />}
              borderBottomWidth={1}
              borderColor={colorMode === 'dark' ? '#333' : null}
              py="8px"
              fontWeight="500"
              onClick={() => onOpenLink(nftData.file?.file_http_url)}
            >
              Download Source File
            </MenuItem>
          )}

          {/* <MenuItem
          icon={<FiEyeOff fontSize="18px" />}
          borderBottomWidth={1}
          py="8px"
          fontWeight="500"
          onClick={() => onHide({ id: nftData.file_id, name: nftData?.metadata?.name })}
        >
          Hide
        </MenuItem> */}
        </MenuList>
      </Menu>
    </Fragment>
  );
};

export default DropdownMenu;
