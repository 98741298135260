import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Flex, Text, Icon, Box, useColorMode } from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';
import ItemEdition from './item';
import { API } from 'config/api';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import { bgColor } from 'config/data';
import DividerTitle from '../DividerTitle';

const ModalEditions = ({ visible, setVisible, setNftData, nftData = {}, onOpenModal }) => {
  const [step, setStep] = useState(1);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (nftData?.edition?.id) {
      setLoading(true);
      API.get(`/nft/edition/${nftData?.edition?.id}`)
        .then((res) => {
          setMeta(res.data.meta);
          setData(res.data.data);
          setStep(1);
          setSuccess(false);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [nftData?.edition?.id]);

  const onClose = () => {
    setStep(1);
    setSuccess(false);
    setVisible(false);
    setNftData({});
  };

  const paginationHandler = (page) => {
    setLoading(true);
    API.get(`/nft/edition/${nftData?.edition?.id}?page=${page}`)
      .then((res) => {
        setMeta(res.data.meta);
        setData(res.data.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Modal size="xl" isOpen={visible} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        mt={{ base: '30px', md: '236px' }}
        w={{ base: '90%', md: '530px' }}
        bg={bgColor[colorMode]}
        color={colorMode === 'dark' ? 'gray.300' : null}
      >
        <ModalBody p="20px 20px 0">
          <Fragment>
            <Flex cursor="pointer" pos="absolute" top="20px" onClick={onClose} zIndex={3}>
              <Icon as={FiArrowLeft} fontSize="18px" />
            </Flex>
            <Flex justifyContent="center" alignItems="center" mb="10px" w="100%" textAlign="center" zIndex={2}>
              <Text fontSize="18px" fontWeight="bold" textAlign="center">
                Editions Preview
              </Text>
            </Flex>

            <DividerTitle />
          </Fragment>
          <Box minH="400px">
            {loading ? (
              <Loader />
            ) : (
              data.map((i, idx) => (
                <ItemEdition
                  key={i.id}
                  nftData={i}
                  type="not_for_sale"
                  onOpenModal={onOpenModal}
                  setVisible={setVisible}
                />
              ))
            )}
            {!loading && meta && (
              <Pagination
                pagination={meta}
                onPageChange={paginationHandler}
                pageRangeDisplayed={1}
                marginPagesDisplayed={2}
              />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalEditions;
