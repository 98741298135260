import React from 'react';
import { Flex, Text, useColorMode } from '@chakra-ui/react';

const Sold = ({}) => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      bg={colorMode === 'dark' ? '#141414' : '#f7f7f7'}
      borderRadius="3px"
      px="20px"
      py="11px"
      cursor="pointer"
      id="sold"
    >
      <Text fontSize="14px" fontWeight="bold">
        SOLD
      </Text>
    </Flex>
  );
};
export default Sold;
