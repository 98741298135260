import numeral from 'numeral';
import { isEmpty } from 'lodash';
import hash from 'object-hash';

export const unslugify = (slug) => {
  const result = slug.replace(/\_/g, ' ');
  return result.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getArrayFilter = (filter = []) => {
  const arr = isEmpty(filter) ? [] : Array.isArray(filter) ? filter : filter.split(',');
  return arr;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getFileName = (name) => {
  let fileName = name.replace(' ', '');
  fileName = name.replace(/["'()]/g, '');
  fileName = fileName.replace(/\.(?=.*\.)/g, '');
  return fileName;
};

export const generateHash = (assetData) => {
  const assetHashHex = hash(assetData);
  const assetHash = Number(window.BigInt.asUintN(52, `0x${assetHashHex}`));
  return assetHash;
};

export const shareOnFacebook = (urlNft) => {
  var url = `https://www.facebook.com/sharer/sharer.php?u=${urlNft}`;
  window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
  return false;
};

export const shareOnTwitter = (urlNft) => {
  var url = `https://twitter.com/intent/tweet?url=${urlNft}`;
  window.open(url, 'TwitterWindow', 600, 300);
  return false;
};

export const pad2 = (number) => {
  return (number < 10 ? '0' : '') + number;
};
export const setHttp = (link) => {
  if (link.search(/^http[s]?\:\/\//) == -1) {
    link = 'http://' + link;
  }
  return link;
};

export const validURL = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const parseUsername = (url = '') => {
  let output = url;
  let matches;
  // Parse username
  matches = url.match(
    /(?:https?:\/\/)?(?:www.)?(?:twitter|medium|facebook|vimeo|instagram)(?:.com\/)?([@a-zA-Z0-9-_]+)/im
  );
  output = matches?.length ? matches[1] : output;
  return output;
};

export const truncate = (input, max) => (input.length > max ? `${input.substring(0, max)}...` : input);
export const formatPercent = (val) => `${numeral(val).format('0.00')}%`;
export const number = (val) => Number(`${numeral(val).format('00.00')}`);
export const priceTwoDigits = (val) => `${numeral(val).format('0,0.00')}`;

export const peso = (val) => {
  const number = val ? Number(val.toString().replace(/[^0-9.-]+/g, '')) : 0;
  return `$${number}`;
};

export const price = (val) => {
  const number = val ? Number(val.toString().replace(/[^0-9.-]+/g, '')) : 0;
  return `$${number}`;
  // return `${number % 10 ? numeral(number).format('$0,0.00') : numeral(number).format('$0,0')}`;
};
// export const price = (val) => `${numeral(val).format('$0,0.00')}`;
export const priceOne = (val) => `${numeral(val).format('$0,0')}`;
export const priceUsd = (val) => `${numeral(val).format('$0,0.00')} USD`;
export const priceStx = (val) => `${numeral(val / 1000000).format('0,0')} STX`;
export const twoDigits = (val) => `${numeral(val).format('0,0.00')} STX`;

export const formatTime = (time, pad) => {
  return String(time).padStart(pad || 2, '0');
};

export const convertToSlug = (Text) =>
  Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

export const slugify = (str) => {
  str = str?.replace(/^\s+|\s+$/g, ''); // trim
  str = str?.toLowerCase();
  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuunc------';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
};

const handleSumIsHigher = (vals, expectedTotal, sum, itemIndex) => {
  const max = Math.max(...vals);
  const exceeding = sum - expectedTotal;
  const valsCopy = [...vals];

  if (exceeding > expectedTotal) {
    const tempValue = expectedTotal / vals.length;
    return Array(vals.length).fill(tempValue);
  }

  const higherThanExceding = valsCopy.find((val) => val >= exceeding);

  if (higherThanExceding.length <= 0 || valsCopy.indexOf(higherThanExceding) === itemIndex) {
    const dividedExceeding = (expectedTotal - valsCopy[itemIndex]) / (valsCopy.length - 1);
    console.log({ exceeding, dividedExceeding });
    let temp = Array(valsCopy.length).fill(dividedExceeding);
    temp[itemIndex] = valsCopy[itemIndex];
    return [...temp];
  }

  const indexOfMax = valsCopy.indexOf(max);

  if (indexOfMax === itemIndex) {
    if (indexOfMax >= vals.length) {
      valsCopy[indexOfMax - 1] = valsCopy[indexOfMax - 1] - exceeding;
    } else {
      valsCopy[indexOfMax + 1] = valsCopy[indexOfMax + 1] - exceeding;
    }
  } else {
    valsCopy[indexOfMax] = valsCopy[indexOfMax] - exceeding;
  }

  return [...valsCopy];
};

const handleSumIsLower = (vals, expectedTotal, sum, itemIndex) => {
  const min = Math.min(...vals);
  const needed = expectedTotal - sum;
  const valsCopy = [...vals];
  const indexOfMin = valsCopy.indexOf(min);

  if (indexOfMin === itemIndex) {
    if (indexOfMin >= vals.length) {
      valsCopy[indexOfMin - 1] = valsCopy[indexOfMin - 1] + needed;
    } else {
      valsCopy[indexOfMin + 1] = valsCopy[indexOfMin + 1] + needed;
    }
  } else {
    valsCopy[indexOfMin] = valsCopy[indexOfMin] + needed;
  }

  return [...valsCopy];
};

export const balanceValues = (expectedTotal, vals, itemIndex) => {
  const sum = vals.reduce((a, b) => a + b, 0);
  if (sum === expectedTotal) return [...vals];

  if (sum > expectedTotal) {
    const preparedArray = handleSumIsHigher(vals, expectedTotal, sum, itemIndex);
    return [...preparedArray];
  }

  if (sum < expectedTotal) {
    const preparedArray = handleSumIsLower(vals, expectedTotal, sum, itemIndex);
    return [...preparedArray];
  }
};
