import React, { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { Flex, Text, useColorMode } from '@chakra-ui/react';
import useUser from 'lib/useUser';
import { AuthContext } from 'contexts/auth/auth.context';
import { useAuthDispatch } from 'contexts/0Auth';
import axios from 'axios';

const NotForSale = ({ nftData, onOpenModal }) => {
  // const [isShown, setIsShown] = useState(true);
  const { user } = useUser();

  const router = useRouter();

  const { goToOauth } = useAuthDispatch();

  const pathname = router.asPath;

  const user_id = nftData?.user?.id;
  const { authDispatch } = useContext(AuthContext);
  const { colorMode } = useColorMode();

  const onOpenOffer = async () => {
    if (user?.isLoggedIn) {
      onOpenModal(nftData, 'offer');
    } else {
      // authDispatch({ type: 'SHOW_MODAL' });
      await axios.post('/api/current', { pathname });
      goToOauth();
    }
  };
  // onClick={() => onOpenModal(nftData, 'bid')}
  return (
    <Flex
      bg={user_id !== user.id ? '#373737' : colorMode === 'dark' ? '#141414' : '#f7f7f7'}
      // bg={'#f7f7f7'}
      color={user_id !== user.id ? '#fff' : null}
      borderRadius="3px"
      px="10px"
      py="11px"
      cursor="pointer"
      onClick={user_id !== user.id ? () => onOpenOffer() : null}
    >
      <Text fontSize="10px" fontWeight="bold">
        {user_id !== user.id ? 'MAKE AN OFFER' : 'NOT FOR SALE'}
        {/* NOT FOR SALE */}
      </Text>
    </Flex>
  );
};
export default NotForSale;
