/* eslint-disable react/no-children-prop */
import React, { Fragment, useCallback, useEffect } from 'react';
import {
  Flex,
  Text,
  Button,
  Icon,
  Image,
  Avatar,
  Box,
  SimpleGrid,
  InputGroup,
  Input,
  InputLeftElement,
  Tooltip,
  useColorMode,
  border,
} from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
import notification from 'components/Notifications';
import { MdClose } from 'react-icons/md';
import { FaArrowCircleUp } from 'react-icons/fa';
import { BsInfoCircleFill } from 'react-icons/bs';
import SuccessBid from './success-bid';
// import ErrorBid from './error-bid';
import { API } from 'config/api';
import { number, priceTwoDigits } from 'utils/utility';
import { bgColor, bgColorB, borderColor } from 'config/data';

const bids = [5, 10, 15, 20];
const BoxBid = ({ active, setActive, number }) => {
  const { colorMode } = useColorMode();
  // console.log(active);
  return (
    <Box
      borderRadius="18px"
      borderWidth={1}
      borderColor={borderColor[colorMode]}
      py="5px"
      fontSize="14px"
      bg={active === number ? (colorMode === 'dark' ? '#141414' : 'black') : 'null'}
      color={active === number ? 'white' : null}
      onClick={() => setActive(number)}
      cursor="pointer"
    >
      <Text fontWeight="500" textAlign="center">
        {number === 0 ? 'Custom' : `+${number}%`}
      </Text>
    </Box>
  );
};

const ModalPlaceBid = ({ visible, setVisible, nftData = {}, onButton, access_token }) => {
  const { file, name, user, price_usd } = nftData;
  // console.log('nftData', nftData);

  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [active, setActive] = React.useState(5);
  const [show, setShow] = React.useState(false);
  const [custom, setCustom] = React.useState(null);
  const [empty, setEmpty] = React.useState(false);
  // console.log('amount', nftData?.auction?.highest_bid?.amount);
  const priceNFT = number(nftData?.auction?.highest_bid?.amount / 100 || price_usd);
  const { colorMode } = useColorMode();
  // console.log('priceNFT', priceNFT);

  const diference = (Number(priceNFT) * (active === 0 ? active : active)) / 100;
  const myBid = number(Number(priceNFT) + diference);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      console.log('esc pressed');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  useEffect(() => {
    setSuccess(false);
  }, []);

  const onSubmit = () => {
    setLoading(true);
    const config = { headers: { Authorization: `Bearer ${access_token}` } };
    API.post(`/nft/${nftData.id}/bid`, { amount: (custom ? custom : myBid) * 100, currency: 'usd' }, config)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setSuccess(true);
        setShow(false);
      })
      .catch((err) => {
        setLoading(false);
        notification('error', `❌${err.response.data.message}`);
      });
  };

  const onClickButton = () => {
    setSuccess(false);
    onButton();
  };

  const randomize = (myArray) => {
    return myArray[Math.floor(Math.random() * myArray.length)];
  };

  const isEmpty = (str) => !str.trim().length;

  const arr = [<SuccessBid key="1" onButton={onClickButton} />];
  // const arr = [<SuccessBid key="1" onButton={onClickButton} />, <ErrorBid key="2" onButton={onClickButton} />];

  return (
    <Modal size="lg" isOpen={visible} onClose={() => setVisible(false)} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        mt={{ base: '20px', md: '236px' }}
        w={{ base: '90%', md: '540px' }}
        bg={bgColor[colorMode]}
        color={colorMode === 'dark' ? 'gray.300' : null}
      >
        <ModalBody p={0}>
          {success ? (
            randomize(arr)
          ) : (
            <Fragment>
              <Flex
                pos="relative"
                py="15px"
                borderBottomWidth={2}
                borderColor={borderColor[colorMode]}
                alignItems="center"
                justifyContent="center"
              >
                <Icon
                  as={MdClose}
                  pos="absolute"
                  onClick={() => setVisible(false)}
                  left="20px"
                  cursor="pointer"
                  fontSize="25px"
                />
                <Text fontSize="16px" fontWeight="500">
                  Place a bid
                </Text>
              </Flex>
              <Flex
                py="15px"
                px="35px"
                borderBottomWidth={2}
                borderColor={borderColor[colorMode]}
                justifyContent="center"
              >
                <Flex borderWidth={1} borderColor={borderColor[colorMode]} p="8px 10px" w="100%" borderRadius="4px">
                  <Image
                    src={file?.thumbnail_http_url}
                    w="52px"
                    h="52px"
                    alt=""
                    mr="10px"
                    borderRadius={4}
                    objectFit="cover"
                  />
                  <Flex flexDirection="column">
                    <Text fontSize="14px" fontWeight="bold">
                      {name}
                    </Text>
                    <Flex alignItems="center">
                      <Avatar name={user?.username} size="xs" w="24px" h="24px" mr="6px" bg="#BC3153" color="#fff" />
                      <Text fontSize="12px">@{user?.username}</Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex py="15px" w="100%" flexDirection="column">
                <Box w="100%" px="35px" borderBottomWidth={2} borderColor={borderColor[colorMode]} pb="5px">
                  <SimpleGrid columns={active === 0 ? 5 : 4} spacing="10px" pb="10px">
                    {bids.map((i) => (
                      <BoxBid
                        key={i}
                        number={i}
                        setActive={() => {
                          setActive(i);
                          setCustom(null);
                          setEmpty(false);
                          setShow(false);
                        }}
                        active={active}
                        price_usd={Number(price_usd)}
                      />
                    ))}
                    {active === 0 && <BoxBid number={0} setActive={() => setActive(0)} active={active} />}
                  </SimpleGrid>

                  <Flex id="info" p="10px 0 15px">
                    <Icon as={BsInfoCircleFill} mr="10px" />
                    <Text fontSize="12px" fontWeight="500">
                      Or enter a custom amount below
                    </Text>
                  </Flex>

                  <Flex
                    id="your-bid"
                    bg={bgColorB[colorMode]}
                    flexDirection="column"
                    pt="20px"
                    pb="30px"
                    alignItems="center"
                    mb="13px"
                  >
                    <Text>Your bid</Text>
                    <Flex alignItems={show ? 'inherit' : 'baseline'}>
                      {show ? (
                        <InputGroup w={show ? '200px' : '200px'} mt="4px" border={1} mr="10px">
                          <InputLeftElement
                            pointerEvents="none"
                            fontSize="24px"
                            children="$"
                            fontWeight="bold"
                            mt="0px"
                          />
                          <Input
                            defaultValue={number(custom ? custom : myBid)}
                            type="number"
                            // variant="unstyled"
                            placeholder="0"
                            height="42px"
                            fontSize="42px"
                            fontWeight="bold"
                            ml={0}
                            _placeholder={{ color: '#7f7f7f' }}
                            _invalid={{ borderColor: '#e53e3e' }}
                            _focus={{ borderColor: '#000' }}
                            onChange={(e) => {
                              if (isEmpty(e.target.value)) {
                                setCustom(null);
                                setEmpty(true);
                              } else {
                                setCustom(e.target.value);
                                setEmpty(false);
                              }
                            }}
                          />
                          {/* <InputRightElement width="45px">
                            {show && (
                              <Button variant="unstyled" size="sm" onClick={() => setShow(false)}>
                                <Icon fontSize="25px" color="#16161d" as={BsFillCheckSquareFill} />
                              </Button>
                            )}
                          </InputRightElement> */}
                        </InputGroup>
                      ) : (
                        <Tooltip hasArrow label="Click to enter custom amount">
                          <Flex mr="10px" cursor="pointer" onClick={() => setShow(true)}>
                            <Text mr="5px" fontSize="24px" fontWeight="bold">
                              $
                            </Text>
                            <Text fontWeight="bold" fontSize="42px" lineHeight="1.2em">
                              {priceTwoDigits(custom ? custom : myBid)}
                            </Text>
                          </Flex>
                        </Tooltip>
                      )}

                      <Icon as={FaArrowCircleUp} color="#07cc78" fontSize="20px" mt={show ? -5 : 0} />
                    </Flex>
                  </Flex>
                </Box>

                <Box w="100%" px="35px">
                  <Button
                    width="100%"
                    isLoading={loading}
                    type="submit"
                    height="40px"
                    mt="12px"
                    variant="black"
                    _disabled={{ background: '#7f7f7f', color: 'white' }}
                    fontSize="14px"
                    fontWeight="500"
                    _hover={{ bg: '#7f7f7f' }}
                    onClick={onSubmit}
                    isDisabled={empty ? true : custom && custom < myBid ? true : false}
                  >
                    Place a bid
                  </Button>
                </Box>
              </Flex>
            </Fragment>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalPlaceBid;
