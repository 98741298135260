import axios from 'axios';
import useSwr from 'swr';

export const useHttp = (url, token) => {
  const fetcher = (url, token) =>
    axios.get(url, { headers: { Authorization: `Bearer ${token}` } }).then((res) => res.data);
  const { data, error } = useSwr(token ? [url, token] : null, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useHttpBid = (url, isBid) => {
  const fetcher = (url) => axios.get(url).then((res) => res.data);
  const { data, error } = useSwr(isBid ? [url, isBid] : null, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};
